.three-column-box {
  display: flex;
  gap: 20px;
  margin: 20px 0;

  .column-box {
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .column-content {
      padding: 30px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      p {
        margin: 0;
        text-align: left;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;

    .column-box {
      .column-content {
        padding: 20px;
      }
    }
  }
} 