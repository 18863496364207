.expandable-image-card {
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .expandable-image-card-content {
    display: flex;
    flex-direction: row;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .image-section {
    width: 33%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .mobile-image {
      display: none;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .text-section {
    width: 75%;
    padding: 30px;
    color: white;

    h2 {
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: bold;
    }
    .Collapsible{
      padding-left: 0px;
    }
    .description {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 1.6;
    }

    .accordion-trigger {
      cursor: pointer;
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.9;
      }

      .arrow {
        transition: transform 0.3s ease;
        
        &.up {
          transform: rotate(180deg);
        }
      }
    }

    .expanded-content {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      font-size: 16px;
      line-height: 1.6;
    }
  }

  @media (max-width: 768px) {
    .expandable-image-card-content {
      flex-direction: column;
    }

    .image-section {
      width: 100%;
      background: none;

      .mobile-image {
        display: block;
        width: 100%;
        height: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .text-section {
      width: 100%;
      padding: 20px;
    }
  }
} 